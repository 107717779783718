import { Switch, Route, useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.css';
import Layout from './components/Layout';
import { theme } from './setting';
import { ThemeProvider } from '@mui/material/styles';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { oktaAuth } from './auth/oktaAuth';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { UserProvider } from './contexts/UserProvider';
import Overview from './views/Overview';
import Shifts from './views/Shifts';
import Breaks from './views/Breaks';
import Plant from './views/Plant';
import Reports from './views/Reports';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
    },
    mutations: {
      retry: 2,
    }
  }
});

export default function App() {
  const history = useHistory();
  const restoreOriginalUri = useCallback(async () => {
    history.replace(toRelativeUrl('', window.location.origin));
  }, [history]);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <QueryClientProvider client={queryClient}>
            <Layout>
              <Switch>
                <Route path='/login/callback' component={LoginCallback} />
                <SecureRoute path='/' exact component={Overview} />
                <SecureRoute path='/reports' exact component={Reports} />
                <SecureRoute path='/:id' exact component={Plant} />
                <SecureRoute path='/:id/shifts' exact component={Shifts} />
                <SecureRoute path='/:id/breaks' exact component={Breaks} />
              </Switch>
            </Layout>
          </QueryClientProvider>
        </UserProvider>
      </ThemeProvider>
    </Security>
  );
}
