import { randomId } from '@mui/x-data-grid-generator';
import { GridRowModes, GridToolbarContainer } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { getApiDate } from '../hooks/api';
import { Grid } from '@mui/material';
import { gridBox, gridButton, gridFilterTitle } from '../assets/styling/grid';
import { Box } from '@mui/system';

export default function EditToolbar ({ setRows, setRowModesModel }) {
  const handAddRecordClick = () => {
    const id = randomId();
    setRows((oldRows) => {
      if (!oldRows[0]) {
        return [
          {
            id,
            isNew: true,
          },
          ...oldRows,
        ];
      }
      // Shallow copy values of first row if available.
      const first = {
        ...oldRows[0],
      };
      // Clone the original date and add 1 day extra.
      if (first.date) {
        first.date = new Date(getApiDate(first.date).valueOf());
        first.date.setUTCDate(first.date.getUTCDate() + 1);
      }
      return [
        {
          ...first,
          id,
          isNew: true,
        },
        ...oldRows
      ];
    });

    setRowModesModel((oldModel) => ({
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'lineNumber' },
      ...oldModel,
    }));
  };

  return (
    <GridToolbarContainer>
      <Box sx={gridBox}>
        <Grid sx={gridFilterTitle}>Add Record</Grid>
        <Button sx={gridButton} variant="contained" color="primary" startIcon={<AddIcon/>} onClick={handAddRecordClick}>Add </Button>
      </Box>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
};
