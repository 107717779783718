import { Grid } from '@mui/material';
import { plants } from '../components/utils';
import PlantCard from '../components/PlantCard';
import { useUser } from '../contexts/UserProvider';

export default function Overview() {
  const { user } = useUser();
  // Only show plants that the user has access to.
  const userPlants = plants.filter((plant) => user.groups.includes(plant.group));

  return (
    <>
      <h1>Plants</h1>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        { userPlants.length === 0 && <Grid item xs={12}><h1>Sorry, you don't have access to any plants. Please contact the administrator.</h1></Grid> }
        { userPlants.map((plant) => {
            return (
              <Grid item xs={6} sm={12/userPlants.length} key={plant.label}>
                <PlantCard
                  id={plant.id}
                  image={plant.image}
                  label={plant.label}
                  description={plant.description}
                />
              </Grid>
            );
        })}

      </Grid>
    </>
  );
}
