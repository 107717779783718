import { DataGrid } from '@mui/x-data-grid';
import EditToolbar from './EditToolbar';
import { LinearProgress } from '@mui/material';
import { lineNumbers, useGridState } from './utils';

export default function BreaksGrid ({ rows, setRows, rowCount, setPage, page, pageSize, isLoading, onRowSave, onRowDelete }) {
  const { getActions, rowModesModel, setRowModesModel,
    handleRowEditStart, handleRowEditStop, processRowUpdate } = useGridState(onRowSave, onRowDelete, rows, setRows);

  const columns = [
    { field: 'lineNumber', headerName: 'Line', editable: true, type: 'singleSelect', valueOptions: lineNumbers, width: 100 },
    { field: 'team', headerName: 'Team', editable: true, width: 100, type: 'number' },
    { field: 'morningBreakDuration', headerName: 'Morning break duration', editable: true, width: 200, type: 'number' },
    { field: 'lunchBreakDuration', headerName: 'Lunch break duration', editable: true, width: 200, type: 'number' },
    { field: 'afternoonBreakDuration', headerName: 'Afternoon break duration', editable: true, width: 200, type: 'number' },
    { field: 'actions', type: 'actions', headerName: 'Actions', width: 100, cellClassName: 'actions', getActions: getActions },
  ];

  return (
    <div className="grid-wrapper">
      <div className="grid-container">
        <DataGrid
          paginationMode='server'
          page={page}
          pageSize={pageSize}
          rowsPerPageOptions={[10]}
          loading={isLoading}
          rowCount={rowCount}
          onPageChange={(newPage) => setPage(newPage)}
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          components={{
            Toolbar: EditToolbar,
            LoadingOverlay: LinearProgress,
          }}
          disableColumnFilter
          componentsProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </div>
    </div>
  );
}
