import React from 'react';
import { Container, Grid } from '@mui/material';
import { VERSION } from '../Constants';

export default function Footer() {
  return (
    <Container
      className='footer'
      maxWidth='xl'
      sx={{ display: { xs: 'none', md: 'block' } }}
    >
      <Grid container direction='row' justifyContent='flex-end' sx={{ mb: 4 }}>
        v { VERSION }
      </Grid>
    </Container>
  );
}
