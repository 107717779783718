import pjson from './../package.json';

const stripTrailingSlash = (str) => {
  if (!str) {
    return '';
  }
  return str.endsWith('/') ?
    str.slice(0, -1) :
    str;
};

export const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
export const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
export const API_ROOT = stripTrailingSlash(process.env.REACT_APP_API_ROOT);
export const VERSION = pjson.version;
