import usePlantsRoute from '../hooks/usePlantsRoute';
import { useDeleteBreakData, useGetBreakData, useSaveBreakData } from '../hooks/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import BreaksGrid from '../components/BreaksGrid';
import { useUser } from '../contexts/UserProvider';
import { hasKeyUserAccess } from '../components/utils';

export default function Breaks() {
  const { user } = useUser();
  const { id: plantId } = usePlantsRoute();
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const filterParams = useMemo(() => {
    return {
      page: page,
      plantId: plantId,
    };
  }, [plantId, page]);
  const { isLoading, isError, error, data } = useGetBreakData(filterParams);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = useCallback(() => setSnackbar(null), [setSnackbar]);
  const saveBreakData = useSaveBreakData();
  const onRowSave = useCallback(async (row) => {
    try {
      const { id } = await saveBreakData.mutateAsync({
        ...row,
        plantId: plantId,
      });
      setSnackbar({ children: 'Break successfully saved', severity: 'success' });
      return id;
    } catch (e) {
      setSnackbar({ children: e.message, severity: 'error' });
      return null;
    }
  }, [plantId, saveBreakData]);
  const deleteBreakData = useDeleteBreakData();
  const onRowDelete = useCallback(async (id) => {
    try {
      await deleteBreakData.mutateAsync({
        plantId,
        id,
      });
      setSnackbar({ children: 'Break successfully deleted', severity: 'success' });
      return true;
    } catch (e) {
      setSnackbar({ children: e.message, severity: 'error' });
      return false;
    }
  }, [plantId, deleteBreakData]);

  useEffect(() => {
    if (data) {
      setRows(data.rows);
      setRowCount(data.rowCount);
      setPageSize(data.pageSize);
    }
  }, [data]);

  if (!hasKeyUserAccess(user)) {
    return <Alert severity='error'>You are not authorized to view this page</Alert>;
  }

  return (
    <>
      <h1>Breaks in { plantId }</h1>
      <div className='grid-wrapper'>
        <div className='grid-container'>
          { isError && <Alert severity='error'> { error.message } </Alert>}
          { <BreaksGrid
              rows={rows}
              setRows={setRows}
              rowCount={rowCount}
              setPage={setPage}
              page={page}
              pageSize={pageSize}
              isLoading={isLoading}
              onRowSave={onRowSave}
              onRowDelete={onRowDelete}
            />
          }
        </div>
      </div>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
}
