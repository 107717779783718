export const gridFilterTitle = {
  fontWeight: 500,
  padding: '0 0 8px',
};

export const gridButton = {
  height: '55px',
};

export const gridBox = {
  margin: '10px 20px',
};
