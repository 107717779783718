import { useLocation } from 'react-router-dom';
import { plants } from '../components/utils';

export default function usePlantsRoute() {
  const location = useLocation();
  const pathRegex = /^\/(\w+)\/?(\w*)$/gm;
  const matches = pathRegex.exec(location.pathname);
  if (!matches) {
    return {
      isPlantRoute: false,
    };
  }

  const plant = plants.filter((plant) => plant.id === matches[1]).shift();
  const id = plant ? plant.id : null;
  const isPlantRoute = !!plant;
  const subRoute = matches[2];

  return {
    isPlantRoute,
    id,
    subRoute
  };
}
