import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  Snackbar,
  TextField,
  InputLabel,
  LinearProgress,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { lineNumbers } from './utils';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import { Box } from '@mui/system';
import { useCallback, useRef, useState } from 'react';
import usePlantsRoute from '../hooks/usePlantsRoute';
import { useSaveShiftData } from '../hooks/api';
import { format } from 'date-fns';
import { TimePicker } from '@mui/x-date-pickers';
import {
  dateDtoValues,
  getHourMinuteValue,
  hourMinuteRegex,
  hourMinuteRegexMessage,
  shiftLengthCalc
} from '../hooks/date';

export default function ShiftsDialog ({ open, onClose }) {
  const { id: plantId } = usePlantsRoute();
  const saveShiftData = useSaveShiftData();
  const [saveProgress, setSaveProgress] = useState(0);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = useCallback(() => setSnackbar(null), [setSnackbar]);
  const hourMinuteFields = {
    startTime: 'Shift Start Time',
    endTime: 'Shift End Time',
    morningBreak: 'Morning Break Time',
    lunchBreak: 'Lunch Break Time',
    afternoonBreak: 'Afternoon Break Time',
  };
  const initialValues = {
    startDate: new Date(),
    endDate: new Date(),
    lineNumber: '',
    team: '',
    plannedQuantity: '',
    ...Object.keys(hourMinuteFields).reduce((obj, key) => ({
      ...obj,
      [key]: ''
    }), {})
  };
  const formEl = useRef();
  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {};
      // Test if value is set.
      Object.keys(initialValues).forEach((key) => {
        if (values[key] === '') {
          errors[key] = 'Required';
        }
      });
      // Test if proper pattern used.
      Object.keys(hourMinuteFields).forEach((key) => {
        const hourMinuteValue = getHourMinuteValue(values[key]);
        const isValid = hourMinuteRegex.test(hourMinuteValue);
        if (!isValid) {
          errors[key] = hourMinuteRegexMessage;
        }
      });
      // Test date range
      if (values.startDate > values.endDate) {
        errors.startDate = 'Start date cannot exceed end date';
      }

      return errors;
    },
    onSubmit: (values) => {
      // Create a series of requests from start to end date with formatted DTO values.
      const { startDate, endDate, ...otherValues } = values;
      const formattedValues = dateDtoValues(otherValues);
      const allDays = dateRange(startDate, endDate);
      setSaveProgress(allDays.length);
      const requests = [];
      const saveAndUpdate = async (day) => {
        const date = format(day, 'yyyy-MM-dd');
        const dto = {
          ...formattedValues,
          plantId,
          date,
          isNew: true,
        };
        dto.shiftLength = shiftLengthCalc({ row: dto });

        // Execute request with API hook.
        const { id } = await saveShiftData.mutateAsync(dto);
        setSaveProgress((prevState) => prevState - 1);

        return id;
      };
      for (const day of allDays) {
        requests.push(saveAndUpdate(day));
      }
      // Wait for and collect all responses.
      Promise.allSettled(requests).then((promises) => {
        const errors = promises
          .filter((promise) => promise.status !== 'fulfilled')
          .map((error) => error.reason);
        if (errors.length > 0) {
          setSnackbar({ children: `Could not save shift: ${errors.join(': ')}`, severity: 'error' });
        } else {
          setSnackbar({ children: 'All saved', severity: 'success' });
        }
        onClose();
      });
    },
  });
  // The submit button is outside the form element because it needs to be in the DialogAction element.
  const onButtonSubmitClick = () => {
    formEl && formEl.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };

  return (
    <>
      <Dialog PaperProps={{ sx: { width: "50%"} }} onClose={onClose} open={open}>
        <DialogTitle>
          Add Shifts
          { saveProgress > 0 &&
            <Box sx={{ display: 'block' }}>
              <LinearProgress variant="determinate" value={saveProgress} />
            </Box>
          }
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ mt: 1, mb: 4 }} >
            This will create a series of shifts for each day starting on the start date to and including the end date.
            Each day will have the values entered in this form.<br />
            The line number, day and team combined are unique per plant and will not be overridden if it already exists.
          </Box>
          <form ref={formEl} onSubmit={formik.handleSubmit}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    required
                    label="Start date"
                    name="startDate"
                    type="date"
                    mask="__-__-____"
                    inputFormat="DD-MM-YYYY"
                    value={formik.values.startDate}
                    onChange={(value) => formik.setFieldValue('startDate', value.$d)}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (<TextField
                        {...params}
                        error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                        helperText={formik.errors.startDate}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    required
                    label="End date"
                    name="endDate"
                    type="date"
                    mask="__-__-____"
                    inputFormat="DD-MM-YYYY"
                    value={formik.values.endDate}
                    onChange={(value) => formik.setFieldValue('endDate', value.$d)}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (<TextField
                        {...params}
                        error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                        helperText={formik.errors.endDate}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
            <FormControl margin="normal" required fullWidth>
              <InputLabel id="line-number-label">Line number</InputLabel>
              <Select
                labelId="line-number-label"
                id="lineNumber"
                name="lineNumber"
                label="Line number"
                value={formik.values.lineNumber}
                onChange={formik.handleChange}
                error={formik.touched.lineNumber && Boolean(formik.errors.lineNumber)}
              >
                {
                  lineNumbers.map(
                    (line) => <MenuItem key={line} value={line}>{ line }</MenuItem>
                  )
                }
              </Select>
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <TextField
                fullWidth
                required
                type="number"
                id="team"
                name="team"
                label="Team"
                InputProps={{
                  inputProps: {
                    max: 255, min: 1
                  }
                }}
                value={formik.values.team}
                onChange={formik.handleChange}
                error={formik.touched.team && Boolean(formik.errors.team)}
                helperText={formik.touched.team && formik.errors.team}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <TextField
                fullWidth
                required
                type="number"
                id="plannedQuantity"
                name="plannedQuantity"
                label="Planned Quantity"
                InputProps={{
                  inputProps: {
                    max: 255, min: 1
                  }
                }}
                value={formik.values.plannedQuantity}
                onChange={formik.handleChange}
                error={formik.touched.plannedQuantity && Boolean(formik.errors.plannedQuantity)}
                helperText={formik.touched.plannedQuantity && formik.errors.plannedQuantity}
              />
            </FormControl>
            {
              Object.keys(hourMinuteFields).map(
                (field) => {
                  return (
                    <FormControl key={field} margin="normal" required fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          required
                          id={field}
                          name={field}
                          ampm={false}
                          views={["hours", "minutes"]}
                          label={hourMinuteFields[field]}
                          value={formik.values[field]}
                          onChange={(value) => formik.setFieldValue(field, (value?.$d || null))}
                          onBlur={formik.handleBlur}
                          renderInput={(params) => (<TextField
                              {...params}
                              error={formik.touched[field] && Boolean(formik.errors[field])}
                              helperText={formik.touched[field] && formik.errors[field]}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  );
                }
              )
            }
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" fullWidth type="button" onClick={onButtonSubmitClick} disabled={saveProgress > 0}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </>
  );
}

/**
 * Return a range of dates (days) with steps (default 1) interval.
 *
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {number} steps
 * @returns {*[]}
 */
function dateRange(startDate, endDate, steps = 1) {
  const dateArray = [];
  const currentDate = new Date(startDate.getTime());
  currentDate.setUTCHours(0,0,0,0);

  while (currentDate <= new Date(endDate.getTime())) {
    dateArray.push(new Date(currentDate.getTime()));
    // Use UTC date to prevent problems with time zones and DST
    currentDate.setUTCDate(currentDate.getUTCDate() + steps);
  }

  return dateArray;
}
