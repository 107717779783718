import { Alert, Grid } from '@mui/material';
import { useGetReportsData } from '../hooks/api';
import { useUser } from '../contexts/UserProvider';
import { hasKeyUserAccess } from '../components/utils';

export default function Reports() {
  const { user } = useUser();
  const { isError, error, data: plants } = useGetReportsData();
  if (!hasKeyUserAccess(user)) {
    return <Alert severity='error'>You are not authorized to view this page</Alert>;
  }

  return (
    <>
      <h1>Reports</h1>
      { isError && <Alert severity='error'> { error.message } </Alert> }
      {
        plants && plants.map((plant, i) => {
          return (
            <div key={i}>
              <h2>{ plant.plantId }</h2>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                { plant.reports.length === 0 && <h4>No reports generated</h4> }
                {
                  plant.reports.map((report, n) => {
                    const reportKey = `report-${plant.id}-${n}`;
                    return (
                      <Grid key={reportKey} item xs={2} sm={4} md={4}>
                        {
                          Object.entries(report).map(([key, val]) => {
                            const recKey = `${reportKey}:${key}`;
                            return (
                              <div key={recKey}><span>{ key }: </span> { val }</div>
                            );
                          })
                        }
                      </Grid>
                    );
                  })
                }
              </Grid>
            </div>
          );
        })
      }
    </>
  );
}
