import { GridToolbarContainer } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import FilterDateRange from './FilterDateRange';
import { Grid } from '@mui/material';
import { gridBox, gridButton, gridFilterTitle } from '../assets/styling/grid';
import { Box } from '@mui/system';
import { useState } from 'react';
import ShiftsDialog from './ShiftsDialog';

export default function ShiftsToolbar ({ setDateRange }) {
  const [openModal, setOpenModal] = useState(false);
  const onClickAdd = () => setOpenModal(true);
  const onClose = () => setOpenModal(false);

  return (
    <GridToolbarContainer>
      <Box sx={gridBox}>
        <Grid sx={gridFilterTitle}>Add shifts</Grid>
        <Button sx={gridButton} variant="contained" color="primary" startIcon={<AddIcon/>} onClick={onClickAdd}>Add</Button>
      </Box>
      <Box sx={gridBox}>
        <Grid sx={gridFilterTitle}>Filter Day</Grid>
        <FilterDateRange setFilterValue={setDateRange}/>
      </Box>
      <ShiftsDialog
        open={openModal}
        onClose={onClose}
      />
    </GridToolbarContainer>
  );
}
