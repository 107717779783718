import { createTheme } from '@mui/material/styles';

const spacing = 8;
// All the following keys are optional.
// We try our best to provide a great default value.
export const theme = createTheme({
  palette: {
    primary: {
      main: '#0f61c4',
      mainLighter: '#eee215',
    },
    secondary: {
      main: '#010c26',
    },
    gray: {
      light: '#676d7d',
      main: '#010c26',
    },
    white: '#FFFFFF',
    black: '#000000',
    red: {
      main: '#FBE9E7',
      dark: '#c62828',
    },
    success: {
      main: '#2e7d32',
      secondary: '#e8f5e9'
    },
    orange: {
      main: '#f75c0e',
    },
    table: {
      header: '#cccccc',
    },
    gradient: {
      dark: '#002b7e',
      lighter: '#004fee',
      light: '#6da2ff',
    }
  },
  spacing,
  drawerWidth: {
    open: 30 * spacing,
    closed: 7 * spacing,
  },
  gap: spacing * 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 1024,
      lg: 1240,
      xl: 1680,
    },
  },
});
