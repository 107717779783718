import usePlantsRoute from '../hooks/usePlantsRoute';
import { hasPlantAccess, plants } from '../components/utils';
import PlantCard from '../components/PlantCard';
import { Alert } from '@mui/material';
import { useUser } from '../contexts/UserProvider';

export default function Plant () {
  const { user } = useUser();
  const { id: plantId } = usePlantsRoute();
  const plant = plants.filter((plant) => plant.id === plantId).shift();
  if (!plant) {
    return <h1>Plant { plantId } not found. Check url please.</h1>;
  }

  if (!hasPlantAccess(user, plantId)) {
    return <Alert severity='error'>You are not authorized to view this page</Alert>;
  }

  return (
    <>
      <h1>Plant { plantId }</h1>
      <PlantCard
        id={plant.id}
        image={plant.image}
        label={plant.label}
        description={plant.description}
      />
    </>
  );
}
