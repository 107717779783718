import React from 'react';
import { Box, Container } from '@mui/system';
import { NavLink } from 'react-router-dom';
import { theme } from '../setting';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import { useUser } from '../contexts/UserProvider';
import usePlantsRoute from '../hooks/usePlantsRoute';

const Header = () => {
  const { user } = useUser();
  const { isPlantRoute, id, subRoute } = usePlantsRoute();

  return (
    <Box
      sx={{
        pt: 3,
        pb: 3,
        backgroundColor: theme.palette.white,
        width: '100vw',
        position: 'relative',
      }}
    >
      <Container
        component='nav'
        maxWidth='xl'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >

        <Box className='logo' component='img' src='/apple-touch-icon.png' alt='PON BIKE Screens Admin' />
        <h1>PON BIKE Screens Admin</h1>

        <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}>
          <NavLink to='/'>
            plants
          </NavLink>

          { isPlantRoute && (
            <>
              <NavLink to={`/${id}`}>{ id }</NavLink>
              { subRoute && <NavLink to={subRoute}>{ subRoute }</NavLink> }
            </>
          )}
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <IconButton
            sx={{
              mx: 1,
              '&:hover': {
                background: 'none',
              },
            }}
          >
            {user && user.name && (
              <Box>
                <CardHeader
                  sx={{
                    p: 0,
                  }}
                  avatar={
                    <Avatar alt={user.name} src='/static/images/avatar/1.jpg' />
                  }
                  title={user.name}
                />
              </Box>
            )}
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
