import { Button, ButtonGroup, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useUser } from '../contexts/UserProvider';
import { keyUserGroup } from './utils';

export default function PlantCard ({ id, image, label, description }) {
  const { user } = useUser();
  const shiftsUrl = `/${id}/shifts`;
  const breaksUrl = `/${id}/breaks`;

  return (
    <Card>
      <CardMedia
        sx={{ height: 140 }}
        image={ image }
        title="plant image"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          { label }
        </Typography>
        <Typography variant="body2" color="text.secondary">
          { description }
        </Typography>
      </CardContent>
      <CardActions>
        <ButtonGroup variant='contained'>
          <Button component={Link} to={shiftsUrl}>Shifts</Button>
          { user.groups.includes(keyUserGroup) && <Button component={Link} to={breaksUrl}>Breaks</Button> }
        </ButtonGroup>
      </CardActions>
    </Card>
  );
}
