import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { oktaAuth } from './oktaAuth';

export default function useAuthUser() {
  const { authState } = useOktaAuth();
  const [user, setUser] = useState({groups: []});

  useEffect(() => {
    const oktaCypress = localStorage.getItem('oktaCypress');
    // For end2end tests. See cypress/support/okta.js.
    if (window.Cypress && oktaCypress) {
      const okta = JSON.parse(oktaCypress);
      const { idToken, accessToken, user } = okta;
      oktaAuth.tokenManager.setTokens({ idToken, accessToken });
      setUser(user);
      if (!user.name) {
        oktaAuth.token
          .getUserInfo()
          .then((info) => {
            setUser({
              ...user,
              ...info,
            });
          });
      }
    } else {
      // Okta authenticates for us, we first need to wait for that to finish.
      if (authState && authState.isAuthenticated) {
        const user = {
          // The custom authentication server needs to be configured to return the groups claim.
          // See https://developer.okta.com/docs/guides/customize-tokens-groups-claim/main/#add-a-groups-claim-for-a-custom-authorization-server
          groups: authState.accessToken.claims.groups || [],
        };
        setUser(user);
        // Update user info if we don't have it yet.
        if (!user.name) {
          oktaAuth.token
            .getUserInfo()
            .then((info) => {
              setUser({
                ...user,
                ...info,
              });
            });
        }
      }
    }

  }, [authState]);

  return [authState, user];
}
