import React, { createContext, useContext } from 'react';
import useAuthUser from '../auth/useAuthUser';

const UserContext = createContext();

/**
 * @param {JSX.element} props - children component
 * @return {JSX.element} ...
 */
export function UserProvider(props) {
  const [authState, user] = useAuthUser();

  return (
    <UserContext.Provider
      value={{
        authState,
        user,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}

/**
 * @return {JSX.element} ...
 */
export function useUser() {
  return useContext(UserContext);
}
