import React from 'react';
import { Box, Container } from '@mui/system';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <Box>
      <Header />
      <Container className='container-75' maxWidth='xl'>{children}</Container>
      <Footer />
    </Box>
  );
};

export default Layout;
